
import { App } from "@capacitor/app";
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
  IonPage,
  IonRouterOutlet,
  alertController,
  useBackButton,
} from "@ionic/vue";
import { dice, trophyOutline, peopleCircleOutline } from "ionicons/icons";
import { useRouter } from "vue-router";

export default {
  name: "MainTabs",
  components: {
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
    IonRouterOutlet,
  },
  setup() {
    const router = useRouter();
    useBackButton(1, () => {
      const currentRoute = router.currentRoute.value.path.split("/")[1];
      const goBackSites = ["settings", "country", "competition", "season", "create"];
      if ((goBackSites.indexOf(currentRoute) > -1)) {
        router.back();
      } else {
        alertController.create({
          header: "Exit app?",
          buttons: [
            {
              text: "Cancel",
              role: "cancel",
              handler: () => {
                return false;
              },
            },
            {
              text: "Yes",
              role: "confirm",
              handler: () => {
                App.exitApp();
              },
            },
          ],
        }).then((alert) => {
          alert.present();
        });
      }
    });
    return {
      dice,
      trophyOutline,
      peopleCircleOutline,
    };
  },
};
